import { LogsEvent } from '@datadog/browser-logs';

const datadogClientToken = process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '';
const environmentName = process.env.REACT_APP_ENVIRONMENT_NAME || 'local';
const isLocal = environmentName === 'local';

const KNOWN_ERRORS = {
  FLASH: ['XHR error GET https://api.maptiler.com/', 'XHR error GET https://basemaps.arcgis.com/'],
};

const isFlashError = (event: LogsEvent) =>
  new URL(event.view.url).pathname.startsWith('/flash/') &&
  KNOWN_ERRORS.FLASH.some(err => event.message.startsWith(err));

export const datadogLogsConfiguration = {
  clientToken: datadogClientToken,
  env: environmentName,
  service: 'portal',
  beforeSend: (event: LogsEvent) => {
    if (event.status !== 'error') return true;

    // Don't send Flash errors
    if ([isFlashError(event)].some(Boolean)) return false;

    // Don't send errors when they happen in a background or inactive tab.
    if (document.hidden) return false;

    if (!navigator.onLine || document.wasDiscarded) {
      event.additionalBrowserInfo = {
        navigatorOnLine: navigator.onLine,
        documentWasDiscarded: document.wasDiscarded,
      };
    }

    return true;
  },
};

export const getDatadogLogsConfiguration = () => {
  return datadogClientToken && !isLocal ? datadogLogsConfiguration : null;
};
